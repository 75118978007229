<template>
  <div id="home">
    <div class="action">
      <div class="cards" :style="{'background-image': 'url(' + require('@/assets/logo-full.png') + ')'}"></div>
      <h1>Sports Betting Parlays<br>Just Got More Interesting...</h1>
      <div class="white--text px-15">
        <v-divider class="my-10 mx-15" dark></v-divider>
        <h2>What is Parlay Poker?</h2>
        <div class="mx-15"><br>Parlay Poker is a new game that combines sports betting with poker.  We assign a traditional playing card to a single bet event or “leg” in a parlay bet based on the odds.  You win by making poker hands with your card-assigned parlay picks.</div>
        <div class="mx-15"><br><b>Parlay Poker is currently in public beta.</b>  Playing the game is strictly for amusement and demonstrative purposes only. The public beta does not offer real money gambling or an opportunity to win real money or real-world prizes. No actual money or anything of value can be won playing the games and services offered through the service, and no actual money is required to play. Although a wallet amount exists, this is not real money and will not change and is used to enable certain features of the game.<br><br>The displayed sports teams, schedules, moneyline odds, spread odds, total odds, results, scores, and outcomes are for demonstrative purposes only.</div>
      </div>
      <!-- <v-btn to="/dashboard" color="black" dark x-large>Play Now</v-btn> -->
    </div>
    <pp-shades></pp-shades>
  </div>
</template>

<script>
export default {
  name: 'Home.vue',
  data(){
    return{
      
    }
  },
}
</script>

<style lang="scss">

  #home{
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    padding: 10vh 0px;
    .cards{
      z-index: 10;
      background: {
        size: contain;
        position: top center;
      }
      width: 100%;
      height: 200px;
    }
    &:before{
      // content: '';
      z-index: 10;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 60%;
      background: rgb(255,255,255);
      background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 52%);
      background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 52%);
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 52%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    }
    .action{
      position: relative;
      z-index: 100;
      margin-top: 0px;
    }
    h1{
      font: {
        size: 60px !important;
        family: 'Raleway';
      }
      line-height: 70px;
      color: #fff;
    }
    h3{
      color: #FAFAFA;
    }
    .v-btn{
      font: {
        size: 20px !important;
      }
      padding: 30px;
      margin-top: 30px;
    }
  }
</style>
